import React from "react";
import { Form } from "react-bootstrap";

export default function Comment({ label, text, id, handleChange = (f) => f }) {
  // 引用元：https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
  const FitToContent = (maxHeight) => {
    var text = id && id.style ? id : document.getElementById(id);
    if (!text) return;

    var adjustedHeight = text.clientHeight;
    if (!maxHeight || maxHeight > adjustedHeight) {
      adjustedHeight = Math.max(text.scrollHeight, adjustedHeight);
      if (maxHeight) adjustedHeight = Math.min(maxHeight, adjustedHeight);
      if (adjustedHeight > text.clientHeight)
        text.style.height = adjustedHeight + "px";
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        id={id}
        as="textarea"
        rows={1}
        maxLength="300"
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={FitToContent(100)}
      />
      <Form.Text id={id} muted className="d-flex justify-content-end">
        {text.length}/300
      </Form.Text>
    </Form.Group>
  );
}
