import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Stack,
  Badge,
  Spinner,
} from "react-bootstrap";
import BookTiles from "../../ui/bookTiles";
import getScriptString from "../../functions/getScriptString";
import getServerUrl from "../../functions/getServerUrl";

export default function QuizModal({
  showModal,
  sections,
  books,
  userId,
  sessionId,
  howToAnswerQuiz,
  isRecord, // 記録前のクイズかどうか
  handleCloseModal = (f) => f,
  handleClickRecord = (f) => f,
}) {
  const [booksPerSections, setBooksPerSections] = useState([]);
  useEffect(() => {
    let booksPerSections = [];
    Object.values(sections).forEach((section) => {
      const booksOfSection = Object.values(books).filter(
        (book) => book.sectionId === section.id
      );
      booksPerSections.push({
        section: section,
        books: booksOfSection,
      });
    });
    setBooksPerSections(booksPerSections);
  }, [sections, books]);

  const [nowHowToAnswerQuiz, setNowHowToAnswerQuiz] = useState("");

  const init = () => {
    setQuiz(null);
    setIsCorrect(null);

    const initialScript = {
      book: null,
      chapter: null,
      verse: null,
    };
    setScript(initialScript);

    const initialSelectingScript = {
      book: false,
      chapter: false,
      verse: false,
    };
    setSelectingScript(initialSelectingScript);

    getQuiz();
  };

  useEffect(() => {
    if (showModal === true) {
      init();
      setNowHowToAnswerQuiz(howToAnswerQuiz);
    }
  }, [showModal]);

  const [quiz, setQuiz] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleClickNext = () => {
    init();
  };

  const getQuiz = async () => {
    const res = await fetch(
      getServerUrl() + "Quizzes?userId=" + userId + "&sessionId=" + sessionId
    );
    const data = await res.json();
    console.log(data);

    setQuiz(data);
  };

  const handleSelectChoice = (choiceNumber) => {
    if (choiceNumber === quiz.answerNumber) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  const initialScript = {
    book: null,
    chapter: null,
    verse: null,
  };
  const [script, setScript] = useState(initialScript);

  useEffect(() => {
    if (script.verse !== null) {
      const answer = quiz.answer;
      if (
        script.book.id === answer.bookId &&
        script.chapter === answer.chapter &&
        script.verse === answer.verse
      ) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }
    }
  }, [script]);

  useEffect(() => {
    if (isCorrect === true && isRecord === true) {
      handleClickRecord();
    }
  }, [isCorrect]);

  const initialSelectingScript = {
    book: false,
    chapter: false,
    verse: false,
  };
  const [selectingScript, setSelectingScript] = useState(
    initialSelectingScript
  );

  const handleSelectBook = (book) => {
    const newScript = {
      book: book,
      chapter: null,
      verse: null,
    };
    setScript(newScript);

    const nextSelectingScript = {
      book: false,
      chapter: true,
      verse: false,
    };
    setSelectingScript(nextSelectingScript);
  };

  const handleSelectChapter = (chapter) => {
    const newScript = {
      chapter: chapter,
      verse: null,
    };
    setScript({ ...script, ...newScript });

    const nextSelectingScript = {
      book: false,
      chapter: false,
      verse: true,
    };
    setSelectingScript(nextSelectingScript);
  };

  const handleSelectVerse = (verse) => {
    const newScript = {
      verse: verse,
    };
    setScript({ ...script, ...newScript });

    const nextSelectingScript = {
      book: false,
      chapter: false,
      verse: false,
    };
    setSelectingScript(nextSelectingScript);
  };

  const handleClickBook = () => {
    const nextSelectingScript = {
      book: true,
      chapter: false,
      verse: false,
    };
    setSelectingScript(nextSelectingScript);

    setIsCorrect(null);
  };

  const handleClickChapter = () => {
    const nextSelectingScript = {
      book: false,
      chapter: true,
      verse: false,
    };
    setSelectingScript(nextSelectingScript);

    setIsCorrect(null);
  };

  const handleClickVerse = () => {
    const nextSelectingScript = {
      book: false,
      chapter: false,
      verse: true,
    };
    setSelectingScript(nextSelectingScript);

    setIsCorrect(null);
  };

  const handleClickSwitchingToScript = () => {
    setNowHowToAnswerQuiz("Script");
  };

  const handleClickSwitchingToChoices = () => {
    setNowHowToAnswerQuiz("Choices");
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal}>
      <Modal.Body className="show-grid">
        <Container>
          {quiz === null ? (
            <Row className="mt-3 mb-3 justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Row>
          ) : (
            <>
              <Row className="mb-3 fs-3">{quiz?.question}</Row>

              {nowHowToAnswerQuiz === "Choices" && (
                <Row lg={4} md={2} sm={2} xs={2}>
                  {quiz?.choices.length !== 0 && (
                    <>
                      {quiz?.choices.map((choice, index) => {
                        const scriptString = getScriptString(choice, books);
                        return (
                          <Col key={index} className="mb-1 d-grid">
                            <Button
                              key={index}
                              variant="outline-success"
                              className="gap-quiz"
                              onClick={() => handleSelectChoice(index)}
                            >
                              {scriptString}
                            </Button>
                          </Col>
                        );
                      })}
                    </>
                  )}
                </Row>
              )}
              {nowHowToAnswerQuiz === "Script" && (
                <Row>
                  <Stack direction="horizontal">
                    <div>
                      <Button
                        variant={
                          selectingScript.book === true
                            ? "info"
                            : "outline-info"
                        }
                        className="gap-book-chapter fs-4"
                        onClick={handleClickBook}
                      >
                        {script.book?.name ?? "-"}
                      </Button>
                    </div>
                    <div>
                      <Stack direction="horizontal">
                        <Button
                          disabled={script.book === null}
                          variant={
                            selectingScript.chapter === true
                              ? "info"
                              : "outline-info"
                          }
                          className="gap-book-chapter fs-4"
                          onClick={handleClickChapter}
                        >
                          {script.chapter ?? "-"}
                        </Button>
                      </Stack>
                    </div>
                    <div>:</div>
                    <div>
                      <Stack direction="horizontal">
                        <Button
                          disabled={
                            script.book === null || script.chapter === null
                          }
                          variant={
                            selectingScript.verse === true
                              ? "info"
                              : "outline-info"
                          }
                          className="gap-book-chapter fs-4"
                          onClick={handleClickVerse}
                        >
                          {script.verse ?? "-"}
                        </Button>
                      </Stack>
                    </div>
                  </Stack>

                  {selectingScript.book === true && (
                    <BookTiles
                      className="mt-4"
                      booksPerSections={booksPerSections}
                      handleSelect={handleSelectBook}
                    />
                  )}
                  {selectingScript.chapter === true && (
                    <Container className="mt-4">
                      <Row>
                        <Col>章を選択してください。</Col>
                      </Row>
                      <Row xs={6}>
                        {Array.from(
                          Array(script.book.chapters.length).keys()
                        ).map((chapter) => (
                          <Col key={chapter + 1} className="mb-3 d-grid">
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={() => handleSelectChapter(chapter + 1)}
                            >
                              {chapter + 1}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </Container>
                  )}
                  {selectingScript.verse === true && (
                    <Container className="mt-4">
                      <Row>
                        <Col>節を選択してください。</Col>
                      </Row>
                      <Row xs={6}>
                        {Array.from(
                          {
                            length:
                              script.book.chapters[script.chapter - 1]
                                .endVerse -
                              script.book.chapters[script.chapter - 1]
                                .startVerse +
                              1,
                          },
                          (_, i) =>
                            i +
                            script.book.chapters[script.chapter - 1].startVerse
                        ).map((verse) => (
                          <Col key={verse} className="mb-3 d-grid">
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={() => handleSelectVerse(verse)}
                            >
                              {verse}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </Container>
                  )}
                </Row>
              )}

              <Row className="mt-4">
                <Col>
                  {nowHowToAnswerQuiz === "Choices" && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={handleClickSwitchingToScript}
                    >
                      聖句の指定に切り替える
                    </Button>
                  )}
                  {nowHowToAnswerQuiz === "Script" && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={handleClickSwitchingToChoices}
                    >
                      選択肢に切り替える
                    </Button>
                  )}
                </Col>
              </Row>

              <Stack
                direction="horizontal"
                className="justify-content-center mt-4"
              >
                <div>
                  {isCorrect === true && <Badge bg="success">正解</Badge>}
                  {isCorrect === false && <Badge bg="danger">不正解</Badge>}
                </div>
              </Stack>
              {isCorrect && isRecord && <div>記録中です...</div>}
            </>
          )}
        </Container>
      </Modal.Body>
      {isCorrect && !isRecord && (
        <Modal.Footer>
          <Button variant="success" onClick={handleClickNext}>
            次へ
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
