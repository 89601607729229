import React, { Component } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import getCommentString from "../../functions/getCommentString";
import getReadingRangeString from "../../functions/getReadingRangeString";

export class RecordModal extends Component {
  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.handleCloseModal}>
        <Modal.Body className="show-grid">
          <Container>
            {this.props.readingRecord && (
              <>
                <Row>
                  {this.props.readingRecord.date
                    .toString()
                    .substring(0, 10)
                    .replaceAll("-", "/")}
                  &nbsp; &nbsp;
                  {getReadingRangeString({
                    book: this.props.books[
                      this.props.readingRecord.readingRange.bookId
                    ],
                    chapter: this.props.readingRecord.readingRange.chapter,
                    startVerse:
                      this.props.readingRecord.readingRange.startVerse,
                    endVerse: this.props.readingRecord.readingRange.endVerse,
                  })}
                </Row>
                <Row>{getCommentString(this.props.readingRecord.comment)}</Row>
              </>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.handleClickNextReadingRange}
          >
            続きを読む
          </Button>
          <Button variant="danger" onClick={this.props.handleClickDeleteRecord}>
            削除する
          </Button>
          <Button variant="info" onClick={this.props.handleClickEditing}>
            編集する
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
