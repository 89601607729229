import React from "react";
import { Stack, OverlayTrigger, Tooltip } from "react-bootstrap";
import getDateString from "../functions/getDateString";

export default function MonthlyCalendar({
  themeColor,
  weekHeatmaps,
  handleClick = (f) => f,
}) {
  return (
    <>
      <Stack direction="horizontal" className="justify-content-center">
        <div className="week guide-text">日</div>
        <div className="week guide-text">月</div>
        <div className="week guide-text">火</div>
        <div className="week guide-text">水</div>
        <div className="week guide-text">木</div>
        <div className="week guide-text">金</div>
        <div className="week guide-text">土</div>
      </Stack>
      {weekHeatmaps.map((weekHeatmap, indexWeekHeatmap) => (
        <Stack
          key={indexWeekHeatmap}
          direction="horizontal"
          className="justify-content-center"
        >
          {weekHeatmap.map((dayOfWeekHeatmap, indexDayOfWeekHeatmap) => {
            if (dayOfWeekHeatmap === null) {
              return (
                <div key={indexDayOfWeekHeatmap} className="calendar"></div>
              );
            } else {
              const isToday =
                getDateString(new Date()) ===
                getDateString(dayOfWeekHeatmap.date);
              return (
                <OverlayTrigger
                  key={indexDayOfWeekHeatmap}
                  placement="top"
                  overlay={
                    <Tooltip>
                      <>
                        {getDateString(dayOfWeekHeatmap.date, "/")}{" "}
                        {dayOfWeekHeatmap.chapterNumber}章（
                        {dayOfWeekHeatmap.verseNumber}節）
                      </>
                    </Tooltip>
                  }
                >
                  <div
                    className="calendar"
                    style={{
                      backgroundColor:
                        themeColor?.heatmapColors[dayOfWeekHeatmap.colorIndex],
                      ...(isToday && {
                        border: "solid",
                        borderColor: themeColor?.heatmapColors[3],
                      }),
                    }}
                    onClick={() => handleClick(dayOfWeekHeatmap.date)}
                  ></div>
                </OverlayTrigger>
              );
            }
          })}
        </Stack>
      ))}
    </>
  );
}
