import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Stack,
  ListGroup,
  ToggleButton,
  Card,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  PencilSquare,
  EyeSlashFill,
} from "react-bootstrap-icons";
import Header from "./Header";
import ReadingRangeSelectionModal from "./modals/ReadingRangeSelectionModal";
import QuizModal from "./modals/QuizModal";
import { RecordModal } from "./modals/RecordModal";
import MonthlyCalendar from "../ui/monthlyCalendar";
import getServerUrl from "../functions/getServerUrl";
import getDateString from "../functions/getDateString";
import getCommentString from "../functions/getCommentString";
import getWeekHeatmaps from "../functions/getWeekHeatmaps";
import getReadingRangeString from "../functions/getReadingRangeString";
import getReadingRangeUrl from "../functions/getReadingRangeUrl";
import getNextReadingRange from "../functions/getNextReadingRange";
import getPlanString from "../functions/getPlanString";
import getDailyFromReadingRecords from "../functions/getDailyFromReadingRecords";

const _version = "2.1.0";

export default function Home() {
  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId");
  const auth = {
    userId: userId,
    sessionId: sessionId,
  };
  const guest = localStorage.getItem("guest");

  const [sections, setSections] = useState({});
  const [books, setBooks] = useState({});

  const [showRecommend, setShowRecommend] = useState(false);
  const [countOfRecentRecords, setCountOfRecentRecords] = useState(-1);

  const initialUserInfo = {
    howToAnswerQuiz: null,
    isQuizTakenBeforeRecord: false,
    themeColor: null,
    isOneYearPlanDisplayed: false,
  };
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  const initialReadingRange = {
    book: null,
    chapter: "-",
    startVerse: "-",
    endVerse: "-",
  };
  const [readingRange, setReadingRange] = useState(initialReadingRange);
  const [readingRangeString, setReadingRangeString] = useState("");

  const [recentReadingRecords, setRecentReadingRecords] = useState([]);

  const [plan, setPlan] = useState("");

  const [showReadingRangeSelectionModal, setShowReadingRangeSelectionModal] =
    useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);

  const [selectedReadingRecord, setSelectedReadingRecord] = useState(null);

  const initialYearAndMonthOfCalendar = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  };
  const [yearAndMonthOfCalendar, setYearAndMonthOfCalendar] = useState(
    initialYearAndMonthOfCalendar
  );

  const [weekHeatmaps, setWeekHeatmaps] = useState([]);
  const [bookHeatmap, setBookHeatmap] = useState(null);

  const [coveredVersesReadingRecords, setCoveredVersesReadingRecords] =
    useState([]);
  const [recentDailyReadingRecords, setRecentDailyReadingRecords] =
    useState(null);

  const [canTakeQuiz, setCanTakeQuiz] = useState(false);

  const [areCommentsHidden, setAreCommentsHidden] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (Object.keys(books).length === 0) return;
    initForm();
  }, [books]);

  useEffect(() => {
    getCalendarHeatmap();
  }, [yearAndMonthOfCalendar]);

  useEffect(() => {
    if (selectedReadingRecord === null) return;
    handleShowRecordModal();
  }, [selectedReadingRecord]);

  useEffect(() => {
    setReadingRangeString(getReadingRangeString(readingRange));

    getCoverdVersesReadingRecords();
    getPlan();

    getChapterHeatmap();

    window.scrollTo(0, 0);
  }, [readingRange]);

  const init = async () => {
    getAreCommentsHidden();

    await getAllInfo();
    await getUserInfo();
    await getCountOfScripturesToMemorize();

    localStorage.setItem("version", _version);
  };

  const initForm = async () => {
    await getCalendarHeatmap();
    await getRecentReadingRecords();
  };

  const getAllInfo = async () => {
    const version = String(localStorage.getItem("version"));

    const getSections = async () => {
      const res = await fetch(
        getServerUrl() +
          "Books/sections?userId=" +
          userId +
          "&sessionId=" +
          sessionId
      );
      const data = await res.json();

      let newSections = {};

      // 連想配列にする
      data.sections.forEach((section) => {
        newSections[section.id] = section;
      });

      setSections(newSections);

      const jsonSections = JSON.stringify(newSections);
      localStorage.setItem("sections", jsonSections);
    };

    const getBooks = async () => {
      const res = await fetch(
        getServerUrl() +
          "Books/books?userId=" +
          userId +
          "&sessionId=" +
          sessionId
      );
      const data = await res.json();

      let newBooks = {};

      // 連想配列にする
      data.books.forEach((book) => {
        newBooks[book.id] = book;
      });

      setBooks(newBooks);

      const jsonBooks = JSON.stringify(newBooks);
      localStorage.setItem("books", jsonBooks);
    };

    // books
    const jsonBooks = localStorage.getItem("books");
    if (jsonBooks === null || jsonBooks === "undefined") {
      await getBooks();
    } else if (version !== _version) {
      await getBooks();
    } else {
      const newBooks = JSON.parse(jsonBooks);
      setBooks(newBooks);
    }

    // sections
    const jsonSections = localStorage.getItem("sections");
    if (jsonSections === null || jsonSections === "undefined") {
      await getSections();
    } else if (version !== _version) {
      await getSections();
    } else {
      const newSections = JSON.parse(jsonSections);
      setSections(newSections);
    }
  };

  const getUserInfo = async () => {
    const res = await fetch(
      getServerUrl() + "Users/me?userId=" + userId + "&sessionId=" + sessionId
    );
    const data = await res.json();
    const user = data.user;

    const newUserInfo = {
      howToAnswerQuiz: user.howToAnswerQuiz,
      isQuizTakenBeforeRecord: user.isQuizTakenBeforeRecord,
      themeColor: user.themeColor,
      isOneYearPlanDisplayed: user.isOneYearPlanDisplayed,
    };
    setUserInfo(newUserInfo);
  };

  const getCountOfScripturesToMemorize = async () => {
    const res = await fetch(
      getServerUrl() +
        "ScripturesToMemorize/range/all?userId=" +
        userId +
        "&sessionId=" +
        sessionId +
        "&offset=0&limit=100"
    );
    const data = await res.json();
    const count = data.totalCount;

    setCanTakeQuiz(count !== 0);
  };

  const getCoverdVersesReadingRecords = async () => {
    if (readingRange.book === null) return;

    const res = await fetch(
      getServerUrl() +
        "ReadingRecords/range/covered_verses?userId=" +
        userId +
        "&sessionId=" +
        sessionId +
        "&bookId=" +
        readingRange.book.id +
        "&chapter=" +
        readingRange.chapter +
        "&startVerse=" +
        readingRange.startVerse +
        "&endVerse=" +
        readingRange.endVerse +
        "&offset=0&limit=100"
    );
    const data = await res.json();
    const readingRecords = data.readingRecords;
    setCoveredVersesReadingRecords(readingRecords);
  };

  const getRecentReadingRecords = async () => {
    const res = await fetch(
      getServerUrl() +
        "ReadingRecords/range/all?userId=" +
        userId +
        "&sessionId=" +
        sessionId +
        "&offset=0&limit=100"
    );
    const data = await res.json();
    const readingRecords = data.readingRecords;
    console.log(readingRecords);

    setRecentReadingRecords(readingRecords);

    const dailyReadingRecords = getDailyFromReadingRecords(readingRecords);
    setRecentDailyReadingRecords(dailyReadingRecords);

    // 通読範囲を設定する
    const newReadingRange = getNextReadingRange(
      readingRecords.length !== 0 ? readingRecords[0] : null,
      books
    );
    setReadingRange(newReadingRange);

    // ゲスト用ユーザー登録アラートの表示を判定する
    if (guest !== null) {
      if (readingRecords.length > 0 && readingRecords.length % 10 === 0) {
        setCountOfRecentRecords(readingRecords.length);
        setShowRecommend(true);
      } else {
        setShowRecommend(false);
      }
    }
  };

  const getCalendarHeatmap = async () => {
    const date = new Date(
      yearAndMonthOfCalendar.year,
      yearAndMonthOfCalendar.month - 1,
      1
    );
    const first = new Date(date.getFullYear(), date.getMonth(), 1);
    const startDate = getDateString(first);
    const last = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const endDate = getDateString(last);

    const res = await fetch(
      getServerUrl() +
        "ReadingRecords/heatmap/calendar?userId=" +
        userId +
        "&sessionId=" +
        sessionId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
    const data = await res.json();
    let dayHeatmaps = data.dayHeatmaps;
    dayHeatmaps = dayHeatmaps.map((dayHeatmap) => {
      dayHeatmap.date = new Date(dayHeatmap.date);
      return dayHeatmap;
    });
    const weekHeatmaps = getWeekHeatmaps(dayHeatmaps);

    setWeekHeatmaps(weekHeatmaps);
  };

  const getChapterHeatmap = async () => {
    const book = readingRange.book;
    if (book === null) return;

    // 過去一年間から取得する
    var date = new Date();
    const lastYearDate = new Date(
      date.getFullYear() - 1,
      date.getMonth(),
      date.getDate()
    );
    const startDate = getDateString(lastYearDate);
    const endDate = getDateString(date);

    const res = await fetch(
      getServerUrl() +
        "ReadingRecords/heatmap/chapter?userId=" +
        userId +
        "&sessionId=" +
        sessionId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
    const data = await res.json();
    console.log(data);
    let bookHeatmap = data.bookHeatmaps.find(
      (bookHeatmap) => bookHeatmap.bookId === book.id
    );
    bookHeatmap = {
      book: books[bookHeatmap.bookId],
      chapterHeatmaps: bookHeatmap.chapterHeatmaps,
    };
    console.log(bookHeatmap);

    setBookHeatmap(bookHeatmap);
  };

  const handleLogonFromGuest = () => {
    window.location.href = "/logon_from_guest";
  };

  const handleSelectReadingRange = (readingRange) => {
    setReadingRange(readingRange);

    handleCloseReadingRangeSelectionModal();
  };
  const handleShowReadingRangeModal = () => {
    setShowReadingRangeSelectionModal(true);
  };
  const handleCloseReadingRangeSelectionModal = () => {
    setShowReadingRangeSelectionModal(false);
  };

  const handleShowQuizModal = () => {
    setShowQuizModal(true);
  };
  const handleCloseQuizModal = () => {
    setShowQuizModal(false);
  };

  const handleShowRecordModal = () => {
    setShowRecordModal(true);
  };
  const handleCloseRecordModal = () => {
    setShowRecordModal(false);
  };

  const handleMoveToNew = () => {
    sessionStorage.setItem(
      "isQuizTakenBeforeRecord",
      userInfo.isQuizTakenBeforeRecord
    );
    sessionStorage.setItem("howToAnswerQuiz", userInfo.howToAnswerQuiz);
    sessionStorage.setItem("canTakeQuiz", canTakeQuiz);

    const jsonReadingRange = JSON.stringify(readingRange);
    sessionStorage.setItem("readingRange", jsonReadingRange);

    const jsonCoveredVersesReadingRecords = JSON.stringify(
      coveredVersesReadingRecords
    );
    sessionStorage.setItem(
      "coveredVersesReadingRecords",
      jsonCoveredVersesReadingRecords
    );

    window.location.href = "/new";
  };

  const handleMoveToEdit = () => {
    const jsonSelectedReadingRecord = JSON.stringify(selectedReadingRecord);
    sessionStorage.setItem("selectedReadingRecord", jsonSelectedReadingRecord);

    window.location.href = "/edit";
  };

  const handleClickDeleteRecord = async () => {
    var result = window.confirm("記録を削除します。本当によろしいですか？");

    if (result) {
      handleCloseRecordModal();
      const data = {
        auth: auth,
        createTime: selectedReadingRecord.createTime,
      };
      console.log(data);

      try {
        const res = await fetch(getServerUrl() + "ReadingRecords/delete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!res.ok) {
          const text = await new Response(res.body).text();
          window.alert(text);
          console.error(text);
        }

        await initForm();
      } catch (e) {
        window.alert("失敗しました。\n" + e);
        console.error(e);
      }
    }
  };

  const handleMoveToLastMonth = () => {
    const date = new Date(
      yearAndMonthOfCalendar.year,
      yearAndMonthOfCalendar.month - 2,
      1
    );

    const newYearAndMonthOfCalendar = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
    setYearAndMonthOfCalendar(newYearAndMonthOfCalendar);
  };

  const handleMoveToNextMonth = () => {
    const date = new Date(
      yearAndMonthOfCalendar.year,
      yearAndMonthOfCalendar.month,
      1
    );

    const newYearAndMonthOfCalendar = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
    setYearAndMonthOfCalendar(newYearAndMonthOfCalendar);
  };

  const handleTakeQuiz = () => {
    handleShowQuizModal();
  };

  const handleClickRecentReadingRecord = (readingRecord) => {
    setSelectedReadingRecord(readingRecord);
  };

  const handleClickNextReadingRange = () => {
    handleCloseRecordModal();

    // 通読範囲を設定する
    const newReadingRange = getNextReadingRange(selectedReadingRecord, books);
    setReadingRange(newReadingRange);
  };

  const handleClickEditing = () => {
    handleCloseRecordModal();

    handleMoveToEdit();
    const book = books[selectedReadingRecord.readingRange.bookId];
    const date = new Date(selectedReadingRecord.date);

    const newReadingRange = {
      book: book,
      chapter: selectedReadingRecord.readingRange.chapter,
      startVerse: selectedReadingRecord.readingRange.startVerse,
      endVerse: selectedReadingRecord.readingRange.endVerse,
    };
    setReadingRange(newReadingRange);
  };

  const getPlan = async () => {
    if (readingRange.book === null) return;

    const res = await fetch(
      getServerUrl() +
        "Plans?userId=" +
        userId +
        "&sessionId=" +
        sessionId +
        "&bookId=" +
        readingRange.book.id +
        "&chapter=" +
        readingRange.chapter +
        "&verse=" +
        readingRange.startVerse
    );
    const data = await res.json();
    const newPlan = data.plan;
    const planString = getPlanString(
      newPlan.startBookId,
      newPlan.startChapter,
      newPlan.startVerse,
      newPlan.endBookId,
      newPlan.endChapter,
      newPlan.endVerse,
      books
    );

    setPlan(planString);
  };

  const getAreCommentsHidden = () => {
    if (localStorage.getItem("areCommentsHidden") === null) {
      localStorage.setItem("areCommentsHidden", false);
      setAreCommentsHidden(false);
    } else {
      setAreCommentsHidden(
        JSON.parse(localStorage.getItem("areCommentsHidden"))
      );
    }
  };
  const clickCheckboxOfAreCommentsHidden = (checked) => {
    localStorage.setItem("areCommentsHidden", checked);
    setAreCommentsHidden(checked);
  };

  return (
    <>
      <ReadingRangeSelectionModal
        showModal={showReadingRangeSelectionModal}
        books={books}
        sections={sections}
        initialReadingRange={readingRange}
        recentReadingRecords={recentReadingRecords}
        handleCloseModal={handleCloseReadingRangeSelectionModal}
        handleSelect={handleSelectReadingRange}
      />

      <QuizModal
        showModal={showQuizModal}
        books={books}
        sections={sections}
        userId={userId}
        sessionId={sessionId}
        howToAnswerQuiz={userInfo.howToAnswerQuiz}
        isRecord={false} // 記録前のクイズかどうか
        handleCloseModal={handleCloseQuizModal}
        handleClickRecord={null}
      />

      <RecordModal
        showModal={showRecordModal}
        handleCloseModal={handleCloseRecordModal}
        books={books}
        readingRecord={selectedReadingRecord}
        handleClickNextReadingRange={handleClickNextReadingRange}
        handleClickDeleteRecord={handleClickDeleteRecord}
        handleClickEditing={handleClickEditing}
      />

      <Header />

      <Container className="mt-3 mb-3">
        {showRecommend && (
          <Row className="mb-3">
            <Col>
              <Alert
                variant="success"
                onClose={() => setShowRecommend(false)}
                dismissible
              >
                <Alert.Heading>
                  通読記録が {countOfRecentRecords} 件に達しました。
                </Alert.Heading>
                <div className="mb-1">
                  ゲストユーザーは1か月間データの更新がないと自動的にアカウントが削除されます。
                  <br />
                  正規ユーザーとしてアカウント登録するとアカウントが削除されることはありません。
                  正規ユーザーとしてアカウント登録することをご検討ください。
                </div>
                <Stack direction="horizontal">
                  <Button
                    variant="outline-success"
                    onClick={handleLogonFromGuest}
                  >
                    正規ユーザーとしてアカウント登録
                  </Button>
                </Stack>
              </Alert>
            </Col>
          </Row>
        )}

        <Row className="mb-2"></Row>
        <Card className="mb-2">
          <Card.Header>今から読む範囲</Card.Header>
          <Card.Body>
            <Stack direction="horizontal" gap={3}>
              <div className="fs-4">{readingRangeString}</div>
              <div>
                <Button
                  variant="outline-info"
                  className="gap-book-chapter"
                  size="lg"
                  onClick={handleShowReadingRangeModal}
                >
                  変更
                </Button>
              </div>
            </Stack>
            {userInfo.isOneYearPlanDisplayed === true && (
              <Stack direction="horizontal" className="mb-3">
                計画：{plan}
              </Stack>
            )}
          </Card.Body>
          <Card.Footer>
            <div className="d-grid">
              <Button variant="success" size="lg" onClick={handleMoveToNew}>
                読む
              </Button>
            </div>
          </Card.Footer>
        </Card>
        <Row>
          <Col>
            <Row>
              <Col lg={4} sm={6}>
                <Card className="mb-2">
                  <Card.Header>月間カレンダー</Card.Header>
                  <Card.Body>
                    <Stack
                      direction="horizontal"
                      className="mb-2 justify-content-center"
                      gap={3}
                    >
                      <Button
                        variant="success"
                        size="sm"
                        onClick={handleMoveToLastMonth}
                      >
                        <ArrowLeftCircle></ArrowLeftCircle>
                      </Button>
                      {yearAndMonthOfCalendar.year}/
                      {yearAndMonthOfCalendar.month.toString().padStart(2, "0")}
                      <Button
                        variant="success"
                        size="sm"
                        onClick={handleMoveToNextMonth}
                      >
                        <ArrowRightCircle></ArrowRightCircle>
                      </Button>
                    </Stack>
                    <MonthlyCalendar
                      themeColor={userInfo.themeColor}
                      weekHeatmaps={weekHeatmaps}
                    />
                    <Card.Link href="/graph/calendar">もっと見る</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} sm={6}>
                <Card className="mb-2">
                  <Card.Header>章ごとの進捗</Card.Header>
                  <Card.Body>
                    <Row className="mb-2">
                      <Col>
                        <div className="item chapter-book-name">
                          <small>{bookHeatmap?.book.shortName}</small>
                        </div>
                        {bookHeatmap?.chapterHeatmaps.map(
                          (chapterHeatmap, indexChapterHeatmap) => (
                            <OverlayTrigger
                              key={indexChapterHeatmap}
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <>
                                    {bookHeatmap?.book.name}
                                    {chapterHeatmap.chapter}章{" "}
                                    {chapterHeatmap.count}回
                                  </>
                                </Tooltip>
                              }
                            >
                              <div
                                key={indexChapterHeatmap}
                                className="item chapter"
                                style={{
                                  backgroundColor:
                                    userInfo.themeColor?.heatmapColors[
                                      chapterHeatmap.colorIndex
                                    ],
                                }}
                              ></div>
                            </OverlayTrigger>
                          )
                        )}
                      </Col>
                    </Row>
                    <Card.Link href="/graph/chapter">もっと見る</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              {canTakeQuiz === true && (
                <Col lg={4} sm={12}>
                  <Card className="mb-2">
                    <>
                      <Card.Header>聖句クイズ</Card.Header>
                      <Card.Body>
                        <Stack
                          direction="horizontal"
                          className="justify-content-center"
                        >
                          <Button variant="success" onClick={handleTakeQuiz}>
                            クイズする
                          </Button>
                        </Stack>
                      </Card.Body>
                    </>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {recentDailyReadingRecords?.length === 0 && (
          <Row className="justify-content-center">
            <Col xs="auto" className="mt-5">
              まだ通読記録がありません。
            </Col>
          </Row>
        )}
        {recentDailyReadingRecords !== null &&
          recentDailyReadingRecords?.length !== 0 && (
            <Row className="mt-3">
              <Col>
                <Stack direction="horizontal">
                  <div>
                    最近の記録 &nbsp; &nbsp;
                    {areCommentsHidden && <EyeSlashFill></EyeSlashFill>}
                  </div>
                  <a className="ms-auto" href="/record">
                    全ての記録を見る
                  </a>
                </Stack>

                {recentDailyReadingRecords?.map(
                  (dailyReadingRecords, index) => (
                    <Card className="mb-3" key={index}>
                      <Card.Header as="h5">
                        {dailyReadingRecords.date
                          .toString()
                          .substring(0, 10)
                          .replaceAll("-", "/")}
                      </Card.Header>
                      <ListGroup className="list-group-flush">
                        {dailyReadingRecords.readingRecords.map(
                          (readingRecord, readingRecordIndex) => (
                            <ListGroup.Item key={readingRecordIndex}>
                              <Stack direction="horizontal">
                                <div>
                                  <a
                                    href={getReadingRangeUrl({
                                      book: books[
                                        readingRecord.readingRange.bookId
                                      ],
                                      chapter:
                                        readingRecord.readingRange.chapter,
                                      startVerse:
                                        readingRecord.readingRange.startVerse,
                                      endVerse:
                                        readingRecord.readingRange.endVerse,
                                    })}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {getReadingRangeString({
                                      book: books[
                                        readingRecord.readingRange.bookId
                                      ],
                                      chapter:
                                        readingRecord.readingRange.chapter,
                                      startVerse:
                                        readingRecord.readingRange.startVerse,
                                      endVerse:
                                        readingRecord.readingRange.endVerse,
                                    })}
                                  </a>
                                  &nbsp; &nbsp;
                                  {!areCommentsHidden &&
                                    getCommentString(readingRecord.comment)}
                                </div>
                                <div className="ms-auto">
                                  <Button
                                    onClick={() =>
                                      handleClickRecentReadingRecord(
                                        readingRecord
                                      )
                                    }
                                    variant="outline-secondary"
                                    size="sm"
                                  >
                                    <PencilSquare></PencilSquare>
                                  </Button>
                                </div>
                              </Stack>
                            </ListGroup.Item>
                          )
                        )}
                      </ListGroup>
                    </Card>
                  )
                )}
              </Col>
            </Row>
          )}

        {recentDailyReadingRecords !== null &&
          recentDailyReadingRecords?.length !== 0 && (
            <Row className="mt-3">
              <Stack direction="horizontal" className="justify-content-left">
                <ToggleButton
                  size="sm"
                  id="toggle-hide"
                  type="checkbox"
                  variant="outline-success"
                  checked={areCommentsHidden}
                  onChange={(e) =>
                    clickCheckboxOfAreCommentsHidden(e.currentTarget.checked)
                  }
                >
                  感想を隠す
                </ToggleButton>
              </Stack>
            </Row>
          )}
      </Container>
    </>
  );
}
