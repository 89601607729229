import React from "react";
import { RouterConfig } from "./route/Route";
import "./style.css";

function App() {
  return (
    <React.StrictMode>
      <RouterConfig />
    </React.StrictMode>
  );
}

export default App;
