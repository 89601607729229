import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../app/Home";
import New from "../app/New";
import Edit from "../app/Edit";
import Logon from "../app/Logon";
import Login from "../app/Login";
import LogonFromGuest from "../app/LogonFromGuest";
import Logout from "../app/Logout";
import Record from "../app/Record";
import GraphCalendar from "../app/GraphCalendar";
import GraphChapter from "../app/GraphChapter";
import Memory from "../app/Memory";
import Setting from "../app/Setting";
import EmailCheck from "../app/EmailCheck";
import GoogleRedirect from "../app/GoogleRedirect";
import LINERedirect from "../app/LINERedirect";

export const RouterConfig = () => {
  return (
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/new" element={<New />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/logon_from_guest" element={<LogonFromGuest />} />
        <Route path="/logon" element={<Logon />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/record" element={<Record />} />
        <Route path="/graph/calendar" element={<GraphCalendar />} />
        <Route path="/graph/chapter" element={<GraphChapter />} />
        <Route path="/memory" element={<Memory />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/email/check" element={<EmailCheck />} />
        <Route path="/google/redirect" element={<GoogleRedirect />} />
        <Route path="/line/redirect" element={<LINERedirect />} />
      </Routes>
    </React.StrictMode>
  );
};
