import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

export default function BookTiles({
  booksPerSections,
  handleSelect,
  className,
}) {
  return (
    <Container className={className}>
      {booksPerSections.map((booksPerSection, indexSection) => (
        <div key={indexSection}>
          <Row className="mt-2">{booksPerSection.section.name}</Row>
          <Row xl={5} lg={5} md={3} sm={3} xs={2}>
            {booksPerSection.books.map((book, indexBook) => (
              <Col key={indexBook} className="mb-1 d-grid">
                <Button
                  variant="outline-info"
                  size="sm"
                  onClick={() => handleSelect(book)}
                >
                  {book.name}
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Container>
  );
}
