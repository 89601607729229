import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import Header from "./Header";
import ReadingRangeSelectionModal from "./modals/ReadingRangeSelectionModal";
import Comment from "../ui/comment";
import getReadingRangeString from "../functions/getReadingRangeString";
import getDateString from "../functions/getDateString";
import getReadingRangeUrl from "../functions/getReadingRangeUrl";
import getServerUrl from "../functions/getServerUrl";

export default function Edit() {
  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId");
  const auth = {
    userId: userId,
    sessionId: sessionId,
  };
  const jsonSections = localStorage.getItem("sections");
  const sections = JSON.parse(jsonSections);

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  const jsonSelectedReadingRecord = sessionStorage.getItem(
    "selectedReadingRecord"
  );
  const selectedReadingRecord = JSON.parse(jsonSelectedReadingRecord);

  const [editingReadingRecordCreateTime, setEditingReadingRecordCreateTime] =
    useState(-1);

  const [date, setDate] = useState("");

  const initialReadingRange = {
    book: null,
    chapter: "-",
    startVerse: "-",
    endVerse: "-",
  };
  const [readingRange, setReadingRange] = useState(initialReadingRange);
  const [readingRangeString, setReadingRangeString] = useState("");

  const initialComment = {
    aboutJehovah: "",
    aboutContribute: "",
    aboutApply: "",
    aboutHelp: "",
  };
  const [comment, setComment] = useState(initialComment);

  const [showReadingRangeSelectionModal, setShowReadingRangeSelectionModal] =
    useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setEditingReadingRecordCreateTime(selectedReadingRecord.createTime);

    const date = new Date(selectedReadingRecord.date);
    setDate(getDateString(date));

    const book = books[selectedReadingRecord.readingRange.bookId];
    const newReadingRange = {
      book: book,
      chapter: selectedReadingRecord.readingRange.chapter,
      startVerse: selectedReadingRecord.readingRange.startVerse,
      endVerse: selectedReadingRecord.readingRange.endVerse,
    };
    setReadingRange(newReadingRange);

    const newComment = {
      aboutJehovah: selectedReadingRecord.comment.aboutJehovah,
      aboutContribute: selectedReadingRecord.comment.aboutContribute,
      aboutApply: selectedReadingRecord.comment.aboutApply,
      aboutHelp: selectedReadingRecord.comment.aboutHelp,
    };
    setComment(newComment);
    console.log(newReadingRange);
  }, []);

  useEffect(() => {
    setReadingRangeString(getReadingRangeString(readingRange));
  }, [readingRange]);

  const handleShowReadingRangeModal = () => {
    setShowReadingRangeSelectionModal(true);
  };
  const handleCloseReadingRangeSelectionModal = () => {
    setShowReadingRangeSelectionModal(false);
  };

  const handleSelectReadingRange = (readingRange) => {
    setReadingRange(readingRange);

    handleCloseReadingRangeSelectionModal();
  };

  const handleOpenReadingRange = () => {
    window.open(getReadingRangeUrl(readingRange), "_blank");
  };

  const handleChangeAboutJehovah = (event) => {
    const newComment = {
      aboutJehovah: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleChangeAboutContribute = (event) => {
    const newComment = {
      aboutContribute: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleChangeAboutApply = (event) => {
    const newComment = {
      aboutApply: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleChangeAboutHelp = (event) => {
    const newComment = {
      aboutHelp: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleChangeDate = (event) => {
    setDate(event.target.value);
  };

  const handleClickCancel = () => {
    window.history.back();
  };

  const handleClickUpdateRecord = async () => {
    setIsProcessing(true);

    const data = {
      auth: auth,
      readingRecord: {
        createTime: editingReadingRecordCreateTime,
        date: date,
        readingRange: {
          bookId: readingRange.book.id,
          chapter: readingRange.chapter,
          startVerse: readingRange.startVerse,
          endVerse: readingRange.endVerse,
        },
        comment: {
          aboutJehovah: comment.aboutJehovah,
          aboutContribute: comment.aboutContribute,
          aboutApply: comment.aboutApply,
          aboutHelp: comment.aboutHelp,
        },
      },
    };
    console.log(data);

    try {
      const res = await fetch(getServerUrl() + "ReadingRecords/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        const text = await new Response(res.body).text();
        window.alert(text);
        console.error(text);
      } else {
        window.location.href = "/";
      }
    } catch (e) {
      window.alert("失敗しました。\n" + e);
      console.error(e);
    }

    setIsProcessing(false);
  };

  return (
    <>
      <ReadingRangeSelectionModal
        showModal={showReadingRangeSelectionModal}
        books={books}
        sections={sections}
        initialReadingRange={readingRange}
        recentReadingRecords={[]}
        handleCloseModal={handleCloseReadingRangeSelectionModal}
        handleSelect={handleSelectReadingRange}
      />

      <Header />

      <Container className="mt-3 mb-3">
        <Row>
          <Col>
            <Stack direction="horizontal" className="mb-3" gap={3}>
              <div>
                <Button
                  variant="outline-info"
                  size="lg"
                  onClick={handleShowReadingRangeModal}
                >
                  <span>{readingRangeString}</span>
                </Button>
              </div>
              <div>
                <Button
                  variant="light"
                  size="sm"
                  onClick={handleOpenReadingRange}
                >
                  開く
                </Button>
              </div>
            </Stack>
          </Col>
        </Row>

        <Card className="mb-3">
          <Card.Header>感想</Card.Header>
          <Card.Body>
            <Comment
              label="1. エホバについてどんなことが分かるか"
              text={comment.aboutJehovah}
              id="aboutJehovah"
              handleChange={handleChangeAboutJehovah}
            />
            <Comment
              label="2. 聖書の中心テーマとどう関係しているか"
              text={comment.aboutContribute}
              id="aboutContribute"
              handleChange={handleChangeAboutContribute}
            />
            <Comment
              label="3. 自分の生活でどのように役立てられるか"
              text={comment.aboutApply}
              id="aboutApply"
              handleChange={handleChangeAboutApply}
            />
            <Comment
              label="4. 人を支え励ますためにどのように使えるか"
              text={comment.aboutHelp}
              id="aboutHelp"
              handleChange={handleChangeAboutHelp}
            />
          </Card.Body>
        </Card>

        <Stack direction="horizontal" className="mb-3">
          <Stack direction="horizontal" className="me-auto">
            <Form.Group>
              <Form.Control
                type="date"
                value={date}
                onChange={(e) => handleChangeDate(e)}
                size="sm"
              />
            </Form.Group>
          </Stack>
          <Stack direction="horizontal">
            <Button variant="light" onClick={handleClickCancel}>
              キャンセル
            </Button>
            <Button
              variant="info"
              className="gap-left"
              onClick={handleClickUpdateRecord}
              size="lg"
              disabled={isProcessing}
            >
              編集
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
