import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Stack,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import Header from "./Header";
import Comment from "../ui/comment";
import QuizModal from "./modals/QuizModal";
import getReadingRangeString from "../functions/getReadingRangeString";
import getServerUrl from "../functions/getServerUrl";
import getDateString from "../functions/getDateString";
import getReadingRangeUrl from "../functions/getReadingRangeUrl";
import getCommentString from "../functions/getCommentString";

export default function New() {
  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId");
  const auth = {
    userId: userId,
    sessionId: sessionId,
  };
  const jsonSections = localStorage.getItem("sections");
  const sections = JSON.parse(jsonSections);

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  const isQuizTakenBeforeRecord =
    sessionStorage.getItem("isQuizTakenBeforeRecord") === "true";
  const howToAnswerQuiz = sessionStorage.getItem("howToAnswerQuiz");
  const canTakeQuiz = sessionStorage.getItem("canTakeQuiz") === "true";

  const jsonReadingRange = sessionStorage.getItem("readingRange");
  const readingRange = JSON.parse(jsonReadingRange);

  const jsonCoveredVersesReadingRecords = sessionStorage.getItem(
    "coveredVersesReadingRecords"
  );
  const coveredVersesReadingRecords = JSON.parse(
    jsonCoveredVersesReadingRecords
  );

  const [date, setDate] = useState("");

  const [readingRangeString, setReadingRangeString] = useState("");

  const [showQuizModal, setShowQuizModal] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const initialComment = {
    aboutJehovah: "",
    aboutContribute: "",
    aboutApply: "",
    aboutHelp: "",
  };
  const [comment, setComment] = useState(initialComment);

  useEffect(() => {
    console.log("userId", userId);
    console.log("sessionId", sessionId);
    console.log("howToAnswerQuiz", howToAnswerQuiz);
    console.log("isQuizTakenBeforeRecord", isQuizTakenBeforeRecord);
    console.log("canTakeQuiz", canTakeQuiz);
    console.log("readingRange", readingRange);
    console.log("coveredVersesReadingRecords", coveredVersesReadingRecords);

    var date = new Date();
    date = getDateString(date);
    setDate(date);
  }, []);

  useEffect(() => {
    setReadingRangeString(getReadingRangeString(readingRange));
  }, [readingRange]);

  const handleShowQuizModal = () => {
    setShowQuizModal(true);
  };
  const handleCloseQuizModal = () => {
    setShowQuizModal(false);
  };

  const handleClickRecordInQuizModal = () => {
    createRecord();
    handleCloseQuizModal();
  };

  const handleClickCancel = () => {
    window.history.back();
  };

  const createRecord = async () => {
    setIsProcessing(true);
    const createTime = new Date(Date.now()).toISOString();
    const data = {
      auth: auth,
      readingRecord: {
        createTime: createTime,
        date: date,
        readingRange: {
          bookId: readingRange.book.id,
          chapter: readingRange.chapter,
          startVerse: readingRange.startVerse,
          endVerse: readingRange.endVerse,
        },
        comment: {
          aboutJehovah: comment.aboutJehovah,
          aboutContribute: comment.aboutContribute,
          aboutApply: comment.aboutApply,
          aboutHelp: comment.aboutHelp,
        },
      },
    };

    try {
      const res = await fetch(getServerUrl() + "ReadingRecords/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        const text = await new Response(res.body).text();
        window.alert(text);
        console.error(text);
      } else {
        window.location.href = "/";
      }
    } catch (e) {
      window.alert("失敗しました。");
      console.error(e);
    }

    setIsProcessing(false);
  };

  const handleChangeAboutJehovah = (event) => {
    const newComment = {
      aboutJehovah: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleChangeAboutContribute = (event) => {
    const newComment = {
      aboutContribute: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleChangeAboutApply = (event) => {
    const newComment = {
      aboutApply: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleChangeAboutHelp = (event) => {
    const newComment = {
      aboutHelp: event.target.value,
    };
    setComment({ ...comment, ...newComment });
  };

  const handleClickRecord = () => {
    if (isQuizTakenBeforeRecord && canTakeQuiz) {
      handleShowQuizModal();
    } else {
      createRecord();
    }
  };

  const handleChangeDate = (event) => {
    setDate(event.target.value);
  };

  const handleOpenReadingRange = () => {
    window.open(getReadingRangeUrl(readingRange), "_blank");
  };

  return (
    <>
      <Header />

      <QuizModal
        showModal={showQuizModal}
        books={books}
        sections={sections}
        userId={userId}
        sessionId={sessionId}
        howToAnswerQuiz={howToAnswerQuiz}
        isRecord={true} // 記録前のクイズかどうか
        handleCloseModal={handleCloseQuizModal}
        handleClickRecord={handleClickRecordInQuizModal}
      />

      <Container className="mt-3 mb-3">
        <Row>
          <Col>
            <Stack direction="horizontal" className="mb-3" gap={3}>
              <div className="fs-4">{readingRangeString}</div>
              <div>
                <Button
                  variant="light"
                  size="sm"
                  onClick={handleOpenReadingRange}
                >
                  開く
                </Button>
              </div>
            </Stack>
          </Col>
        </Row>

        <Card className="mb-3">
          <Card.Header>感想</Card.Header>
          <Card.Body>
            <Comment
              label="1. エホバについてどんなことが分かるか"
              text={comment.aboutJehovah}
              id="aboutJehovah"
              handleChange={handleChangeAboutJehovah}
            />
            <Comment
              label="2. 聖書の中心テーマとどう関係しているか"
              text={comment.aboutContribute}
              id="aboutContribute"
              handleChange={handleChangeAboutContribute}
            />
            <Comment
              label="3. 自分の生活でどのように役立てられるか"
              text={comment.aboutApply}
              id="aboutApply"
              handleChange={handleChangeAboutApply}
            />
            <Comment
              label="4. 人を支え励ますためにどのように使えるか"
              text={comment.aboutHelp}
              id="aboutHelp"
              handleChange={handleChangeAboutHelp}
            />
          </Card.Body>
        </Card>

        <Stack direction="horizontal" className="mb-3">
          <Stack direction="horizontal" className="me-auto">
            <Form.Group>
              <Form.Control
                type="date"
                value={date}
                onChange={(e) => handleChangeDate(e)}
                size="sm"
              />
            </Form.Group>
          </Stack>
          <Stack direction="horizontal">
            <Button variant="light" onClick={handleClickCancel}>
              キャンセル
            </Button>
            <Button
              variant="success"
              className="gap-left"
              onClick={handleClickRecord}
              size="lg"
              disabled={isProcessing}
            >
              記録
            </Button>
          </Stack>
        </Stack>

        {coveredVersesReadingRecords?.length !== 0 && (
          <Row className="mt-3">
            <Col>
              {readingRangeString} のこれまでの記録
              <ListGroup>
                {coveredVersesReadingRecords?.map((readingRecord, index) => (
                  <ListGroup.Item key={index}>
                    {readingRecord.date
                      .toString()
                      .substring(0, 10)
                      .replaceAll("-", "/")}
                    &nbsp; &nbsp;
                    <a
                      href={getReadingRangeUrl({
                        book: books[readingRecord.readingRange.bookId],
                        chapter: readingRecord.readingRange.chapter,
                        startVerse: readingRecord.readingRange.startVerse,
                        endVerse: readingRecord.readingRange.endVerse,
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getReadingRangeString({
                        book: books[readingRecord.readingRange.bookId],
                        chapter: readingRecord.readingRange.chapter,
                        startVerse: readingRecord.readingRange.startVerse,
                        endVerse: readingRecord.readingRange.endVerse,
                      })}
                    </a>
                    &nbsp; &nbsp;
                    {getCommentString(readingRecord.comment)}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
