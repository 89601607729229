import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Stack,
  ListGroup,
} from "react-bootstrap";
import BookTiles from "../../ui/bookTiles";
import getReadingRangeCandidates from "../../functions/getReadingRangeCandidates";
import getReadingRangeString from "../../functions/getReadingRangeString";
import getDateString from "../../functions/getDateString";

export default function ReadingRangeSelectionModal({
  showModal,
  sections,
  books,
  initialReadingRange,
  recentReadingRecords,
  handleCloseModal = (f) => f,
  handleSelect = (f) => f,
}) {
  const [booksPerSections, setBooksPerSections] = useState([]);
  const [customizedReadingRange, setCustomizedReadingRange] =
    useState(initialReadingRange);

  const [candidateReadingRanges, setCandidateReadingRanges] = useState([]);

  const initialSelectingRange = {
    book: false,
    chapter: false,
    startVerse: false,
    endVerse: false,
  };
  const [selectingRange, setSelectingRange] = useState(initialSelectingRange);

  useEffect(() => {
    let booksPerSections = [];
    Object.values(sections).forEach((section) => {
      const booksOfSection = Object.values(books).filter(
        (book) => book.sectionId === section.id
      );
      booksPerSections.push({
        section: section,
        books: booksOfSection,
      });
    });
    setBooksPerSections(booksPerSections);
  }, [sections, books]);

  useEffect(() => {
    setCustomizedReadingRange(initialReadingRange);
  }, [initialReadingRange]);

  useEffect(() => {
    if (books.length === 0) return;
    setCandidateReadingRanges(
      getReadingRangeCandidates(recentReadingRecords, books)
    );
  }, [recentReadingRecords, books]);

  useEffect(() => {
    if (showModal === true) {
      const nextSelectingRange = {
        book: false,
        chapter: false,
        startVerse: false,
        endVerse: false,
      };
      setSelectingRange(nextSelectingRange);
    }
  }, [showModal]);

  const handleSelectBook = (book) => {
    const newReadingRange = {
      book: book,
      chapter: 1,
      startVerse: book.chapters[0].startVerse,
      endVerse: book.chapters[0].endVerse,
    };
    setCustomizedReadingRange(newReadingRange);

    const nextSelectingRange = {
      book: false,
      chapter: true,
      startVerse: false,
      endVerse: false,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleSelectChapter = (chapter) => {
    const newReadingRange = {
      chapter: chapter,
      startVerse: customizedReadingRange.book.chapters[chapter - 1].startVerse,
      endVerse: customizedReadingRange.book.chapters[chapter - 1].endVerse,
    };
    setCustomizedReadingRange({
      ...customizedReadingRange,
      ...newReadingRange,
    });

    const nextSelectingRange = {
      book: false,
      chapter: false,
      startVerse: true,
      endVerse: false,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleSelectWholeChapter = () => {
    const newReadingRange = {
      startVerse:
        customizedReadingRange.book.chapters[customizedReadingRange.chapter - 1]
          .startVerse,
      endVerse:
        customizedReadingRange.book.chapters[customizedReadingRange.chapter - 1]
          .endVerse,
    };
    setCustomizedReadingRange({
      ...customizedReadingRange,
      ...newReadingRange,
    });

    const nextSelectingRange = {
      book: false,
      chapter: false,
      startVerse: false,
      endVerse: false,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleSelectStartVerse = (startVerse) => {
    const newReadingRange = {
      startVerse: startVerse,
      endVerse:
        customizedReadingRange.book.chapters[customizedReadingRange.chapter - 1]
          .endVerse,
    };
    setCustomizedReadingRange({
      ...customizedReadingRange,
      ...newReadingRange,
    });

    const nextSelectingRange = {
      book: false,
      chapter: false,
      startVerse: false,
      endVerse: true,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleSelectEndVerse = (endVerse) => {
    const newReadingRange = {
      endVerse: endVerse,
    };
    setCustomizedReadingRange({
      ...customizedReadingRange,
      ...newReadingRange,
    });

    const nextSelectingRange = {
      book: false,
      chapter: false,
      startVerse: false,
      endVerse: false,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleClickBook = () => {
    const nextSelectingRange = {
      book: true,
      chapter: false,
      startVerse: false,
      endVerse: false,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleClickChapter = () => {
    const nextSelectingRange = {
      book: false,
      chapter: true,
      startVerse: false,
      endVerse: false,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleClickStartVerse = () => {
    const nextSelectingRange = {
      book: false,
      chapter: false,
      startVerse: true,
      endVerse: false,
    };
    setSelectingRange(nextSelectingRange);
  };

  const handleClickEndVerse = () => {
    const nextSelectingRange = {
      book: false,
      chapter: false,
      startVerse: false,
      endVerse: true,
    };
    setSelectingRange(nextSelectingRange);
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal}>
      <Modal.Body className="show-grid">
        {candidateReadingRanges.length > 0 && (
          <>
            <div>以前の続きを読む</div>
            <Container className="mb-4">
              <ListGroup>
                {candidateReadingRanges?.map((candidate, index) => (
                  <ListGroup.Item key={index}>
                    <Stack direction="horizontal">
                      <Stack direction="horizontal">
                        <div>
                          <Button
                            variant="outline-info"
                            className="gap-book-chapter"
                            size="lg"
                            onClick={() => handleSelect(candidate.readingRange)}
                          >
                            <span>
                              {getReadingRangeString(candidate.readingRange)}
                            </span>
                          </Button>
                        </div>
                        <div>
                          {candidate.date == null
                            ? ""
                            : getDateString(new Date(candidate.date), "/") +
                              "の続き"}
                        </div>
                      </Stack>
                    </Stack>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Container>
          </>
        )}
        <div>通読範囲を設定する</div>
        <Container>
          <Stack direction="horizontal">
            <div>
              <Button
                variant={selectingRange.book === true ? "info" : "outline-info"}
                className="gap-book-chapter"
                size="lg"
                onClick={handleClickBook}
              >
                {customizedReadingRange.book?.name}
              </Button>
            </div>
            <div>
              <Stack direction="horizontal">
                <Button
                  variant={
                    selectingRange.chapter === true ? "info" : "outline-info"
                  }
                  className="gap-book-chapter"
                  size="lg"
                  onClick={handleClickChapter}
                >
                  {customizedReadingRange.chapter}
                </Button>
                {customizedReadingRange?.book?.id === 19 ? "編" : "章"}
              </Stack>
            </div>
            <div>
              <Stack direction="horizontal">
                <Button
                  variant={
                    selectingRange.startVerse === true ? "info" : "outline-info"
                  }
                  className="gap-book-chapter"
                  size="lg"
                  onClick={handleClickStartVerse}
                >
                  {customizedReadingRange.startVerse}
                </Button>
                -
                <Button
                  variant={
                    selectingRange.endVerse === true ? "info" : "outline-info"
                  }
                  className="gap-book-chapter"
                  size="lg"
                  onClick={handleClickEndVerse}
                >
                  {customizedReadingRange.endVerse}
                </Button>
                節
              </Stack>
            </div>
          </Stack>
        </Container>
        {selectingRange.book === true && (
          <BookTiles
            className="mt-4"
            booksPerSections={booksPerSections}
            handleSelect={handleSelectBook}
          />
        )}
        {selectingRange.chapter === true && (
          <Container className="mt-4">
            <Row>
              <Col>章を選択してください。</Col>
            </Row>
            <Row xs={6}>
              {Array.from(
                Array(customizedReadingRange.book.chapters.length).keys()
              ).map((chapter) => (
                <Col key={chapter + 1} className="mb-3 d-grid">
                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => handleSelectChapter(chapter + 1)}
                  >
                    {chapter + 1}
                  </Button>
                </Col>
              ))}
            </Row>
          </Container>
        )}
        {selectingRange.startVerse === true && (
          <Container className="mt-4">
            <Row>
              <Col xs={12} className="mb-3">
                <Button
                  variant="outline-info"
                  onClick={() => handleSelectWholeChapter()}
                >
                  章全体を読む
                </Button>
              </Col>
              <Col>開始節を選択してください。</Col>
            </Row>
            <Row xs={6}>
              {Array.from(
                {
                  length:
                    customizedReadingRange.book.chapters[
                      customizedReadingRange.chapter - 1
                    ].endVerse -
                    customizedReadingRange.book.chapters[
                      customizedReadingRange.chapter - 1
                    ].startVerse +
                    1,
                },
                (_, i) =>
                  i +
                  customizedReadingRange.book.chapters[
                    customizedReadingRange.chapter - 1
                  ].startVerse
              ).map((verse) => (
                <Col key={verse} className="mb-3 d-grid">
                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => handleSelectStartVerse(verse)}
                  >
                    {verse}
                  </Button>
                </Col>
              ))}
            </Row>
          </Container>
        )}
        {selectingRange.endVerse === true && (
          <Container className="mt-4">
            <Row>
              <Col>終了節を選択してください。</Col>
            </Row>
            <Row xs={6}>
              {Array.from(
                {
                  length:
                    customizedReadingRange.book.chapters[
                      customizedReadingRange.chapter - 1
                    ].endVerse -
                    customizedReadingRange.startVerse +
                    1,
                },
                (_, i) => i + customizedReadingRange.startVerse
              ).map((verse) => (
                <Col key={verse} className="mb-3 d-grid">
                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => handleSelectEndVerse(verse)}
                  >
                    {verse}
                  </Button>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Stack direction="horizontal" className="justify-content-end">
          <Button variant="light" onClick={handleCloseModal}>
            キャンセル
          </Button>
          <Button
            variant="success"
            className="gap-left"
            onClick={() => handleSelect(customizedReadingRange)}
          >
            決定
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
