export default function getWeekHeatmaps(dayHeatmaps) {
  let weekHeatmaps = [];
  let currentWeek = new Array(7).fill(null);

  dayHeatmaps.forEach((dayHeatmap) => {
    let date = new Date(dayHeatmap.date);
    let dayOfWeek = date.getDay();

    // 現在の週の配列にdayHeatmapを埋める
    currentWeek[dayOfWeek] = dayHeatmap;

    // もし土曜日なら、現在の週をweekHeatmapsに追加し、リセットする
    if (dayOfWeek === 6) {
      weekHeatmaps.push(currentWeek);
      currentWeek = new Array(7).fill(null);
    }
  });

  // 最後の週にデータがある場合、それを追加する
  if (currentWeek.some((day) => day !== null)) {
    weekHeatmaps.push(currentWeek);
  }

  return weekHeatmaps;
}
