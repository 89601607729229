import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import BookTiles from "../../ui/bookTiles";

export default function BookModal({
  showModal,
  sections,
  books,
  handleCloseModal = (f) => f,
  handleSelect = (f) => f,
}) {
  const [booksPerSections, setBooksPerSections] = useState([]);

  useEffect(() => {
    let booksPerSections = [];
    Object.values(sections).forEach((section) => {
      const booksOfSection = Object.values(books).filter(
        (book) => book.sectionId === section.id
      );
      booksPerSections.push({
        section: section,
        books: booksOfSection,
      });
    });
    setBooksPerSections(booksPerSections);
  }, [sections, books]);

  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal}>
      <Modal.Body className="show-grid">
        <BookTiles
          booksPerSections={booksPerSections}
          handleSelect={handleSelect}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
