import React, { useState, useEffect } from "react";
import RecordsOfDateModal from "./modals/RecordsOfDateModal";
import { Container, Row, Col, Button, Stack, Spinner } from "react-bootstrap";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import MonthlyCalendar from "../ui/monthlyCalendar";
import Header from "./Header";
import getServerUrl from "../functions/getServerUrl";
import getDateString from "../functions/getDateString";
import getWeekHeatmaps from "../functions/getWeekHeatmaps";

export default function GraphCalendar() {
  const [themeColor, setThemeColor] = useState(null);
  const [yearOfCalendar, setYearOfCalendar] = useState(
    new Date().getFullYear()
  );
  const [monthHeatmaps, setMonthHeatmaps] = useState([]);
  const [showRecordsModal, setShowRecordsModal] = useState(false);
  const [date, setDate] = useState("");

  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId");

  useEffect(() => {
    getUserInfo(userId, sessionId);
    getMonthHeatmaps(userId, sessionId);
  }, []);

  useEffect(() => {
    getMonthHeatmaps(userId, sessionId);
  }, [yearOfCalendar]);

  useEffect(() => {
    if (date !== "") {
      handleShowRecordsModal();
    }
  }, [date]);

  const getUserInfo = async (userId, sessionId) => {
    const res = await fetch(
      getServerUrl() + "Users/me?userId=" + userId + "&sessionId=" + sessionId
    );
    const data = await res.json();
    const user = data.user;
    if (user !== undefined) {
      setThemeColor(user.themeColor);
    }
  };

  const getMonthHeatmaps = async (userId, sessionId) => {
    const yearDate = new Date(yearOfCalendar, 0, 1);

    const getYearHeatmap = async () => {
      const first = new Date(yearDate.getFullYear(), 0, 1);
      const startDate = getDateString(first);
      const last = new Date(yearDate.getFullYear(), 12, 0);
      const endDate = getDateString(last);

      const res = await fetch(
        getServerUrl() +
          "ReadingRecords/heatmap/calendar?userId=" +
          userId +
          "&sessionId=" +
          sessionId +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
      );
      const data = await res.json();

      return data.dayHeatmaps;
    };

    const getMonthHeatmaps = (yearHeatmap) => {
      let monthHeatmaps = [];
      for (let month = 0; month < 12; month++) {
        const monthDate = new Date(yearDate.getFullYear(), month, 1);
        const dayHeatmaps = yearHeatmap.filter(
          (dayHeatmap) => dayHeatmap.date.getMonth() === monthDate.getMonth()
        );
        const weekHeatmaps = getWeekHeatmaps(dayHeatmaps);

        monthHeatmaps.push({
          month: month + 1,
          weekHeatmaps: weekHeatmaps,
        });
      }

      return monthHeatmaps;
    };

    let yearHeatmap = await getYearHeatmap();
    yearHeatmap = yearHeatmap.map((dayHeatmap) => {
      dayHeatmap.date = new Date(dayHeatmap.date);
      return dayHeatmap;
    });

    const monthHeatmaps = getMonthHeatmaps(yearHeatmap);
    setMonthHeatmaps(monthHeatmaps);
  };

  const handleMoveToLastYearOfCalendarHeatmap = () => {
    setYearOfCalendar(yearOfCalendar - 1);
    setMonthHeatmaps([]);
  };

  const handleMoveToNextYearOfCalendarHeatmap = () => {
    setYearOfCalendar(yearOfCalendar + 1);
    setMonthHeatmaps([]);
  };

  const handleClickGraph = (date) => {
    setDate(getDateString(date));
  };

  const handleShowRecordsModal = () => {
    setShowRecordsModal(true);
  };
  const handleCloseRecordsModal = () => {
    setShowRecordsModal(false);
    setDate("");
  };

  return (
    <>
      <RecordsOfDateModal
        showModal={showRecordsModal}
        date={date}
        handleCloseModal={handleCloseRecordsModal}
      />

      <Header />

      <Container className="mt-3">
        <Row>
          <Stack direction="horizontal" className="mb-2 justify-content-center">
            <Button
              variant="success"
              size="sm"
              className="gap-right"
              onClick={handleMoveToLastYearOfCalendarHeatmap}
            >
              <ArrowLeftCircle></ArrowLeftCircle>
            </Button>
            {yearOfCalendar}年
            <Button
              variant="success"
              size="sm"
              className="gap-left"
              onClick={handleMoveToNextYearOfCalendarHeatmap}
            >
              <ArrowRightCircle></ArrowRightCircle>
            </Button>
          </Stack>
        </Row>
        {monthHeatmaps.length === 0 && (
          <Row className="mt-3 mb-3 justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        )}
        <Row xxl={4} xl={4} lg={4} md={3} sm={2} xs={2}>
          {monthHeatmaps.map((monthHeatmap, index) => (
            <Col key={index} className="mb-1">
              <div className="text-center">{monthHeatmap.month}月</div>
              <MonthlyCalendar
                themeColor={themeColor}
                weekHeatmaps={monthHeatmap.weekHeatmaps}
                handleClick={handleClickGraph}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            {monthHeatmaps.length !== 0 && (
              <Stack
                direction="horizontal"
                className="mt-3 mb-3 justify-content-end"
              >
                <div className="calendar guide-text">少</div>
                {themeColor?.heatmapColors.map((heatmapColor, index) => (
                  <div
                    key={index}
                    className="calendar"
                    style={{ backgroundColor: heatmapColor }}
                  ></div>
                ))}
                <div className="calendar guide-text">多</div>
              </Stack>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
